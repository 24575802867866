import { navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import EnvelopeSuccess from '../../assets/images/envelope-success.svg'
import { Layout } from '../../components/auth/Layout'
import { SimpleContent } from '../../components/auth/SimpleContent'
import { DatacMessage } from '../../components/common/DatacMessage'
import { useScopedIntl } from '../../hooks'
import { AccountType, resendRegistrationConfirmMessage } from '../../requests'
import { routes } from '../../routes'

interface Props {
  location: {
    hash: string
    state?: {
      email?: string
    }
  }
}

const SignUpSuccessPage: React.FC<Props> = ({ location }) => {
  if (!location?.state?.email) navigate(routes.notFound(AccountType.User))

  const intlSignupSuccess = useScopedIntl('auth.signup_success')
  const resendRegistrationConfirmation = () =>
    new Promise<void>(resolve =>
      resendRegistrationConfirmMessage(
        { token: location.hash.slice(1), email: location.state?.email, accountType: AccountType.User },
        {
          onSuccess: () => resolve(),
          onRequestError: () => {
            DatacMessage.error(intlSignupSuccess('request_error.title'), intlSignupSuccess('request_error.subtitle'))
            resolve()
          },
          onNoSuchInvitation: () => {
            DatacMessage.error(intlSignupSuccess('token_error.title'), intlSignupSuccess('token_error.subtitle'))
            resolve()
          }
        }
      )
    )

  return (
    <Layout accountType={AccountType.User}>
      <SimpleContent
        title={intlSignupSuccess('title')}
        subtitle={intlSignupSuccess('subtitle')}
        message={intlSignupSuccess('no_email')}
        submit={intlSignupSuccess('submit')}
        Image={EnvelopeSuccess}
        onSubmit={resendRegistrationConfirmation}
      />
    </Layout>
  )
}

export default SignUpSuccessPage
export const Head = () => <title>Datacapt - Sign Up</title>
